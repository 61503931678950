<template>
  <div class="view">
    <div class="content-box">
      <h1>Stake SYP to Subscribe the AI indicators</h1>
      <el-row
        class="mt25"
        type="flex"
        :gutter="25"
        justify="space-between"
        align="middle"
      >
        <el-col :span="9" :xs="24">
          <div class="chart" id="tv-chart1"></div>
        </el-col>
        <el-col class="desc" :span="10" :xs="24">
          <h2>Momentum Eruption</h2>
          <ul>
            <li>Short-term momentum indicator</li>
            <li>
              Harnesses KD algorithms to identifying bullish and bearish
              momentum
            </li>
          </ul>
          <a
            href="https://sypool-protocol.gitbook.io/welcome-to-sypool/ai-tradegenius/public-indicators/momentum-eruption"
          >
            Learn more
          </a>
        </el-col>
        <el-col class="try" :span="5" :xs="24">
          <div class="text">Free</div>
          <a
            href="https://www.tradingview.com/u/Sypool/#published-scripts"
            target="_blank"
          >
            <div class="btn-c">Try it now</div>
          </a>
        </el-col>
      </el-row>
      <el-row
        class="mt25"
        type="flex"
        :gutter="25"
        justify="space-between"
        align="middle"
      >
        <el-col :span="9" :xs="24">
          <div class="chart" id="tv-chart2"></div>
        </el-col>
        <el-col class="desc" :span="10" :xs="24">
          <h2>Whale Trend Analysis</h2>
          <ul>
            <li>Short-term momentum indicator</li>
            <li>
              Harnesses KD algorithms to identifying bullish and bearish
              momentum
            </li>
          </ul>
          <a
            href="https://sypool-protocol.gitbook.io/welcome-to-sypool/ai-tradegenius/public-indicators/whale-trend-analysis"
          >
            Learn more
          </a>
        </el-col>
        <el-col class="try" :span="5" :xs="24">
          <div class="text">Free</div>
          <a
            href="https://www.tradingview.com/u/Sypool/#published-scripts"
            target="_blank"
          >
            <div class="btn-c">Try it now</div>
          </a>
        </el-col>
      </el-row>
      <el-row
        class="mt25"
        type="flex"
        :gutter="25"
        justify="space-between"
        align="middle"
      >
        <el-col :span="9" :xs="24">
          <div class="chart" id="tv-chart3"></div>
        </el-col>
        <el-col class="desc" :span="10" :xs="24">
          <h2>Mega Buying Force</h2>
          <ul>
            <li>
              Categorize transactions into major players, institutions, whales,
              and retail traders
            </li>
            <li>Unveil the trading trends of key market participants.</li>
          </ul>
          <a
            href="https://sypool-protocol.gitbook.io/welcome-to-sypool/ai-tradegenius/public-indicators/mega-buying-force"
          >
            Learn more
          </a>
        </el-col>
        <el-col class="try" :span="5" :xs="24">
          <div class="text">Free</div>
          <a
            href="https://www.tradingview.com/u/Sypool/#published-scripts"
            target="_blank"
          >
            <div class="btn-c">Try it now</div>
          </a>
        </el-col>
      </el-row>
      <el-row
        class="mt25"
        type="flex"
        :gutter="25"
        justify="space-between"
        align="middle"
      >
        <el-col :span="9" :xs="24">
          <div class="chart" id="tv-chart4"></div>
        </el-col>
        <el-col class="desc" :span="10" :xs="24">
          <h2>Defensive Nexus Shield</h2>
          <ul>
            <li>Identify key support and resistance levels</li>
            <li>
              Highlight short-term defensive zones against bullish and bearish
              pressures
            </li>
          </ul>
          <a
            href="https://sypool-protocol.gitbook.io/welcome-to-sypool/ai-tradegenius/invite-only-indicators/defensive-nexus-shield"
          >
            Learn more
          </a>
        </el-col>
        <el-col class="try" :span="5" :xs="24">
          <div class="text">5000 SYP/Month</div>
          <div
            v-if="!stakeLeftDays"
            class="btn-c"
            @click="onSubscribe('Defensive Nexus Shield')"
          >
            Subscribe
          </div>
          <div v-if="stakeLeftDays" class="btn-c disabled">
            {{ stakeLeftDays }} Days
          </div>
        </el-col>
      </el-row>
      <el-row
        class="mt25"
        type="flex"
        :gutter="25"
        justify="space-between"
        align="middle"
      >
        <el-col :span="9" :xs="24">
          <div class="chart" id="tv-chart5"></div>
        </el-col>
        <el-col class="desc" :span="10" :xs="24">
          <h2>Trend Sentinel Barrier</h2>
          <ul>
            <li>
              Analyzes market trends by tracking bullish and bearish movements'
              volume
            </li>
            <li>Calculates short-term average costs</li>
          </ul>
          <a
            href="https://sypool-protocol.gitbook.io/welcome-to-sypool/ai-tradegenius/invite-only-indicators/trend-sentinel-barrier"
          >
            Learn more
          </a>
        </el-col>
        <el-col class="try" :span="5" :xs="24">
          <div class="text">5000 SYP/Month</div>
          <div
            v-if="!stakeLeftDays"
            class="btn-c"
            @click="onSubscribe('Defensive Nexus Shield')"
          >
            Subscribe
          </div>
          <div v-if="stakeLeftDays" class="btn-c disabled">
            {{ stakeLeftDays }} Days
          </div>
        </el-col>
      </el-row>
      <el-row
        class="mt25"
        type="flex"
        :gutter="25"
        justify="space-between"
        align="middle"
      >
        <el-col :span="9" :xs="24">
          <div class="chart" id="tv-chart6"></div>
        </el-col>
        <el-col class="desc" :span="10" :xs="24">
          <h2>KD Momentum Matrix</h2>
          <ul>
            <li>Short-term momentum indicator</li>
            <li>
              Harnesses KD algorithms to identifying bullish and bearish
              momentum
            </li>
          </ul>
          <a
            href="https://sypool-protocol.gitbook.io/welcome-to-sypool/ai-tradegenius/invite-only-indicators/kd-momentum-matrix"
          >
            Learn more
          </a>
        </el-col>
        <el-col class="try" :span="5" :xs="24">
          <div class="text">5000 SYP/Month</div>
          <div
            v-if="!stakeLeftDays"
            class="btn-c"
            @click="onSubscribe('Defensive Nexus Shield')"
          >
            Subscribe
          </div>
          <div v-if="stakeLeftDays" class="btn-c disabled">
            {{ stakeLeftDays }} Days
          </div>
        </el-col>
      </el-row>
    </div>
    <SubscribeDialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :balance="balance"
      @subscribe="onStake"
    />
    <StakeDialogVue
      :visible.sync="stakeDialogVisible"
      :product="stakeDialogProduct"
      :balance="balance"
      :refreshing="stakeDialogRefreshing"
      :loading="stakeDialogLoading"
      :amount="stakeDialogAmount"
      @refresh="refresh"
      @stake="actionStake"
    />
  </div>
</template>

<script>
import { Connection } from "@solana/web3.js";
import { rpcConfig, rpcUrl } from "../assets/js";
import {
  createMember,
  createMemberAcc,
  createMemberVaults,
  findMemberKey3,
  getBalanceRewardSpt,
  getBalanceSpt,
  getBalanceSyp,
  getMemberData,
  getMemberDataRaw,
  getMemberKey,
  getMemberPDA2,
  getMemberVaults,
  getRegistrarData,
  getStaking,
  stake,
  stakingList,
} from "../assets/js/stakingv2.1/staking";
import StakeDialogVue from "../components/stakeAiDialog.vue";
import SubscribeDialog from "../components/subscribeDialog.vue";

export default {
  name: "AiIndicators",
  components: { SubscribeDialog, StakeDialogVue },
  data() {
    return {
      connection: new Connection(rpcUrl, rpcConfig),
      balance: 0,
      dialogTitle: undefined,
      dialogVisible: false,
      stakeDialogVisible: false,
      stakeDialogProduct: {
        type: stakingList[1].lockDay,
        apy: stakingList[1].apy,
        lockTime: stakingList[1].lockDayStr,
      },
      stakeDialogAmount: 0,
      stakeDialogRefreshing: false,
      stakeDialogLoading: false,
      stakeLeftDays: 0,
      // staking data
      staking30: {
        lockDayStr: stakingList[1].lockDayStr,
        lockDay: stakingList[1].lockDay,
        apy: stakingList[1].apy,
        stakingValue: 0,
        rewardValue: 0,
        stakingTable: [],
      },
      staking90: {
        lockDayStr: stakingList[2].lockDayStr,
        lockDay: stakingList[2].lockDay,
        apy: stakingList[2].apy,
        stakingValue: 0,
        rewardValue: 0,
        stakingTable: [],
      },
      staking180: {
        lockDayStr: stakingList[3].lockDayStr,
        lockDay: stakingList[3].lockDay,
        apy: stakingList[3].apy,
        stakingValue: 0,
        rewardValue: 0,
        stakingTable: [],
      },
      staking365: {
        lockDayStr: stakingList[4].lockDayStr,
        lockDay: stakingList[4].lockDay,
        apy: stakingList[4].apy,
        stakingValue: 0,
        rewardValue: 0,
        stakingTable: [],
      },
    };
  },
  methods: {
    initTVChart() {
      try {
        new TradingView.chart({
          autosize: true,
          // width: 400,
          height: 250,
          chart: "70StXh8B",
          container_id: "tv-chart1",
        });
        new TradingView.chart({
          autosize: true,
          // width: 400,
          height: 250,
          chart: "T1hiHhzP",
          container_id: "tv-chart2",
        });
        new TradingView.chart({
          autosize: true,
          // width: 400,
          height: 250,
          chart: "MYKYyky5",
          container_id: "tv-chart3",
        });
        new TradingView.chart({
          autosize: true,
          // width: 400,
          height: 250,
          chart: "v259pELe",
          container_id: "tv-chart4",
        });
        new TradingView.chart({
          autosize: true,
          // width: 400,
          height: 250,
          chart: "SGeFibWf",
          container_id: "tv-chart5",
        });
        new TradingView.chart({
          autosize: true,
          // width: 400,
          height: 250,
          chart: "qlkM4Ipv",
          container_id: "tv-chart6",
        });
      } catch (err) {
        console.error("init tv chart error", err);
      }
    },
    async init() {
      await Promise.all([
        this.getBalance(),
        this.getMemberBalance2(30),
        this.getMemberBalance2(90),
        this.getMemberBalance2(180),
        this.getMemberBalance2(365),
      ]);
      this.buildStakeDay();
    },
    onSubscribe(title) {
      this.dialogTitle = title;
      this.dialogVisible = true;
    },
    /**
     * @param {{amount:number;time:number}} item
     */
    onStake(item) {
      this.stakeDialogProduct = this.getProduct(item.time);
      if (!this.stakeDialogProduct) return;
      this.dialogVisible = false;
      this.stakeDialogVisible = true;
      this.stakeDialogAmount = item.amount;
    },
    async getBalance() {
      // get syp balance
      const wallet = this.$store.state.walletConnectInfo.wallet;
      const balance = await getBalanceSyp(this.connection, wallet);
      console.log("syp balance", balance);
      this.balance = balance.amount;
    },
    /**
     * @param {number} item
     */
    getProduct(time) {
      const staking = getStaking(time);
      if (!staking) {
        this.$notify({
          title: "Warning",
          message: `Could not find staking ${time}`,
          type: "warning",
        });
        return;
      }
      const product = {
        type: staking.lockDay,
        apy: staking.apy,
        lockTime: staking.lockDayStr,
      };
      return product;
    },
    /**
     * action stake
     */
    async actionStake(data) {
      if (this.stakeDialogLoading) return;
      const { type, amount } = data;
      this.stakeDialogLoading = true;
      try {
        await this.goStake(amount, type);
      } catch (err) {
        console.log("stake error", err);
        this.$notify({
          title: "Failure",
          message: "Transaction failed",
          type: "error",
        });
      }
      this.stakeDialogLoading = false;
    },
    /**
     * get member
     */
    async getMember(lockDay) {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      // get staking
      let staking = getStaking(lockDay);
      let memberSeed = staking.memberSeed;
      // get member
      let memberKey;
      if (lockDay == 0) {
        memberKey = await getMemberKey(wallet, memberSeed);
      } else {
        // let temp = await getMemberKey2(wallet, memberSeed, this.connection);
        let time = new Date().getTime().toString();
        memberSeed += time;
        memberKey = await getMemberKey(wallet, memberSeed);
      }
      // get pda
      let { memberPDA, nonce } = await getMemberPDA2(
        staking.registrarKey,
        memberKey
      );
      // get member data
      let memberData = await getMemberData(this.connection, memberKey);
      if (!memberData) {
        // create member account
        let res = await createMemberAcc(
          this.connection,
          wallet,
          memberKey,
          memberSeed
        );
        if (res.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Create member account sign cancelled",
            type: "warning",
          });
          return;
        } else if (res.code == -2) {
          this.$notify({
            title: "Error",
            message: "Wait member account time out",
            type: "error",
          });
          return;
        } else if (res.code == -3) {
          console.log(res);
          this.$notify({
            title: "Error",
            message: "Create member account error",
            type: "error",
          });
          return;
        } else if (res.code == 1) {
          memberData = res.data;
        }
      }
      // get member vaults
      let res = getMemberVaults(memberData);
      let memberVaults;
      if (res.code == 0) {
        // create memeber vaults
        let res2 = await createMemberVaults(
          this.connection,
          wallet,
          staking.registrarKey,
          memberKey
        );
        if (res2.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Create member vaults sign cancelled",
            type: "warning",
          });
          return;
        } else if (res2.code == -3) {
          console.log("create member vaults error", res2.data);
          this.$notify({
            title: "Error",
            message: "Create member vaults error",
            type: "error",
          });
          return;
        } else if (res2.code == 1) {
          memberVaults = res2.data;
        }
        // create member
        let res3 = await createMember(
          this.connection,
          wallet,
          memberKey,
          memberVaults,
          staking.registrarKey
        );
        if (res3.code == -2) {
          this.$notify({
            title: "Error",
            message: "Wait member account time out",
            type: "error",
          });
          return;
        } else if (res3.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Create member sign cancelled",
            type: "warning",
          });
          return;
        } else if (res3.code == -3) {
          console.log("create member error", res3.data);
          this.$notify({
            title: "Error",
            message: "Create member error",
            type: "error",
          });
          return;
        } else if (res3.code == 1) {
          memberData = res3.data;
          let res4 = getMemberVaults(memberData);
          memberVaults = res4.data;
        }
      } else if (res.code == 1) {
        memberVaults = res.data;
      }
      return { memberKey, memberVaults, memberPDAKey: memberPDA.toBase58() };
    },
    async getMemberBalance2(lockDay) {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      // const wallet = { publicKey: new PublicKey('DtmerpXbLKEMFsec4SsfB9Fi7WbsZ51dD5aJni6ZurvM') };
      // get staking
      let staking = getStaking(lockDay);
      // get registrar data
      let registrarData = await getRegistrarData(
        this.connection,
        staking.registrarKey
      );
      // get member key
      let list = await findMemberKey3(
        this.connection,
        wallet,
        staking.registrarKey
      );
      // console.log(lockDay, list.length);
      if (list.length > 0) {
        let stakingTable = [];
        let totalValue = 0;
        for (let i = 0; i < list.length; i++) {
          let member = list[i];
          let memberKey = member.pubkey.toBase58();
          // get member data
          let memberData = getMemberDataRaw(member.account);
          if (memberData) {
            // console.log("member key", lockDay, memberKey, memberData);
            if (memberData.lastStakeTs > 0) {
              // calculate left day
              let nowTime = new Date().getTime();
              let leftTime =
                registrarData.withdrawalTimeLock * 1000 -
                (nowTime - memberData.lastStakeTs * 1000);
              if (leftTime < 0) {
                leftTime = 0;
              }
              let leftDay = Math.ceil(leftTime / (1000 * 3600 * 24));
              // get spt balance
              let balance = await getBalanceSpt(this.connection, memberKey);
              if (balance) {
                let { amount } = await getBalanceRewardSpt(
                  this.connection,
                  memberKey
                );
                if (balance.amount > 0 || amount > 0) {
                  // assembly member table data
                  stakingTable.push({
                    value: balance.amount,
                    leftDay,
                    memberKey,
                    reward: amount,
                    lockDay,
                  });
                  totalValue += balance.amount;
                }
              }
            }
          }
        }
        if (lockDay == 30) {
          this.$set(this.staking30, "stakingTable", stakingTable);
          this.$set(this.staking30, "stakingValue", totalValue);
        } else if (lockDay == 90) {
          this.$set(this.staking90, "stakingTable", stakingTable);
          this.$set(this.staking90, "stakingValue", totalValue);
        } else if (lockDay == 180) {
          this.$set(this.staking180, "stakingTable", stakingTable);
          this.$set(this.staking180, "stakingValue", totalValue);
        } else if (lockDay == 365) {
          this.$set(this.staking365, "stakingTable", stakingTable);
          this.$set(this.staking365, "stakingValue", totalValue);
        }
      }
    },
    buildStakeDay() {
      if (this.staking365.stakingTable.length) {
        this.stakeLeftDays = this.staking30.stakingTable[0].leftDay;
      } else if (this.staking180.stakingTable.length) {
        this.stakeLeftDays = this.staking180.stakingTable[0].leftDay;
      } else if (this.staking90.stakingTable.length) {
        this.stakeLeftDays = this.staking90.stakingTable[0].leftDay;
      } else if (this.staking30.stakingTable.length) {
        this.stakeLeftDays = this.staking30.stakingTable[0].leftDay;
      } else {
        this.stakeLeftDays = 0;
      }
    },
    /**
     * go stake
     */
    async goStake(amount, lockDay) {
      console.log("stake", amount, lockDay);
      const wallet = this.$store.state.walletConnectInfo.wallet;
      const member = await this.getMember(lockDay);
      if (!member) {
        console.log("member is null");
        return;
      }
      const { memberKey, memberVaults, memberPDAKey } = member;
      if (!memberKey || !memberVaults || !memberPDAKey) {
        console.log("member is null");
        return;
      }
      let res = await stake(this.connection, wallet, amount, memberKey);
      if (res.code == -1) {
        console.log("stake error", res.data);
        this.$notify({
          title: "Warning",
          message: "Stake sign cancelled",
          type: "warning",
        });
      } else if (res.code == 0) {
        this.$notify({
          title: "Failure",
          message: "Transaction failed",
          type: "error",
        });
        console.log("stake error", res.data);
      } else if (res.code == -3) {
        this.$notify({
          title: "Failure",
          message: "Transaction failed",
          type: "error",
        });
        console.log("stake error", res.data);
      } else if (res.code == 1) {
        let msg = "stake transaction confirmed";
        this.notifyWithSignature(msg, res.data);
        console.log("stake signature", res.data);
      } else {
        this.$notify({
          title: "Failure",
          message: "Transaction failed",
          type: "error",
        });
      }
    },
    refresh() {
      void this.init();
    },
  },
  mounted() {
    this.initTVChart();
    void this.init();
  },
};
</script>

<style lang="less" scoped>
.view {
  height: 100%;
  overflow: hidden auto;
}

.mt25 {
  margin-top: 25px;
}

.content-box {
  margin: 0 auto;
  width: 80%;
  padding: 25px 35px;
  background-color: #131315;
  border-radius: 15px;
}

h1 {
  font-size: 32px;
  font-weight: bold;
}

.chart {
  width: 100%;
  height: 250px;
}

.chart-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
}
.desc {
  a {
    text-decoration: underline;
  }
}
.try {
  .text {
    color: #48bafd;
    text-align: center;
    margin-bottom: 15px;
    font-style: 24px;
  }
}
</style>
