<template>
  <el-dialog :title="localTitle" :visible.sync="localVisible">
    <div class="balance">Balance: {{ balance.toFixed(3) }}</div>
    <div v-for="item in config" :key="item.time" class="item">
      <div class="time">{{ item.timeStr }}</div>
      <div class="amount">{{ item.amount }}</div>
      <div class="btn-c" @click="onSubscribe(item)">Subscribe</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "SubscribeDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: undefined,
    },
    balance: {
      type: Number,
      default: 0.0,
    },
  },
  emits: ["update:visible", "subscribe"],
  data() {
    return {
      config: [
        { timeStr: "1 Month", amount: 5000, time: 30 },
        { timeStr: "3 Months", amount: 12000, time: 90 },
        { timeStr: "6 Month", amount: 25000, time: 180 },
        { timeStr: "1 Year", amount: 40000, time: 365 },
      ],
    };
  },
  methods: {
    /**
     * @param {{amount:number;time:number}} item
     */
    onSubscribe(item) {
      this.$emit("subscribe", { amount: item.amount, time: item.time });
    },
    async init() {
      const res = await this.$axios.post("/getAiIndicatorsConfig");
      this.config = res.data.data;
    },
  },
  mounted() {
    void this.init();
  },
  computed: {
    localVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    localTitle() {
      if (this.title) {
        return `Stake to Subscribe ${this.title}`;
      } else {
        return "Stake to Subscribe";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.balance {
  color: #fff;
  font-size: 16px;
}

.item {
  margin: 25px 0;
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px #444;
  .time {
    width: 30%;
    color: #fff;
    font-size: 18px;
  }
  .amount {
    width: 30%;
    color: #48bafd;
    font-size: 18px;
  }
  .btn-c {
    width: 20%;
  }
}
</style>
